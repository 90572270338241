// src/components/HeaderAnimation.js
import React, { useState, useEffect } from 'react';


const HeaderAnimation = ({ texts }) => {
    const [visibleText, setVisibleText] = useState('');
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
  
    useEffect(() => {
      const currentText = texts[currentTextIndex];
      let index = 0;
      const intervalId = setInterval(() => {
        if (index < currentText.length) {
            var txt = currentText.substring(0,index) + currentText[index];
            setVisibleText(txt);
            index++;
        }
        else if (index === currentText.length) {
          clearInterval(intervalId);
          setTimeout(() => {
            clearText();
          }, 5000); // Wait for 5 second before clearing text
        }
      }, 200); // Adjust the typing speed (interval duration) as needed
  
      return () => clearInterval(intervalId);
    }, [texts, currentTextIndex]);
  
    const clearText = () => {
      const currentText = texts[currentTextIndex];
      let index = currentText.length - 1;
      const intervalId = setInterval(() => {
 
        var txt = currentText.substring(0,index);
        setVisibleText(txt);
        index--;
        
        if (index < 0) {
          clearInterval(intervalId);
          setTimeout(() => {
            changeText();
          }, 500); // Wait for 0.5 seconds before changing text
        }
      }, 50); // Adjust the typing speed (interval duration) as needed
    };
  
    const changeText = () => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    };
  
    return (
      
        <h1 >
            {visibleText}
        </h1>
     
    );
  };
  export default HeaderAnimation;