// src/components/MenuButton.js
import React from 'react';
import { Link } from 'react-scroll';
import TextAnimation from './TextAnimation';

const MenuButton = ({ to, text }) => {
  return (
    <Link
      activeClass="active"
      to={to}
      spy={true}
      smooth={true}
      offset={-70} // Adjust the offset as needed
      duration={500}
    >
      <TextAnimation text={text} animation="bounce" />
    </Link>
  );
};

export default MenuButton;
