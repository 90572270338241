import logo from './logo.svg';
import { Grid } from '@material-ui/core';
import './App.css';
import ScrollingMenu from './components/ScrollingMenu';
import Title from './components/Title'
import Projects from './components/Projects'
import Experience from './components/Experience'
import ContactMe from './components/ContactMe'

function App() {
  return (
    <div className="App">
        <ScrollingMenu />
        <div id="title" className='section title'>
          <header className="App-header">
          
            <Title/>
          </header>
              
        </div> 
        <body>
    
            
            <div id="experience" className="section experience">
              <Experience/>
            </div>
            <div id="projects" className="section projects">
              
              <Projects/>
            </div>
            <div id="contact" className="section contact">
              
              
                  <ContactMe/>
                
            </div>
        </body>
    </div>
  );
}

export default App;
