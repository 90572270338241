// src/components/TextAnimation.js
import React from 'react';
import '../stylesheets/TextAnimation.css';

const TextAnimation = ({ text, animation }) => {
  return (
    <div className={`text-container ${animation}`}>
      <p className="animated-text"  >{text}</p>
    </div>
  );
};

export default TextAnimation;
