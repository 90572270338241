// src/components/ContactMe.js
import React from 'react';
import { Grid } from '@material-ui/core';
import '../stylesheets/ContactMe.css';
import me from '../images/headshot.jpeg';
import { GitHub, LinkedIn } from '@material-ui/icons';

const ContactMe = () => {
  return (
    
    <Grid 
    container spacing={2} 


    justifyContent="center"
    alignItems="center"
    >
      <Grid item xs={12} sm={12} md={12}  >
        <div className="card-content-header">
          <h2>Contact Me</h2>
        </div>
            
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
            <div className="card-content">
              <h3>Mikayla Hill</h3>
              <h4>Software Engineer</h4>
              <a href="mailto:mikayla.hill8@gmail.com">mikayla.hill8@gmail.com</a>
            </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
          <div className="social-media-icons">
          <a href="https://github.com/Mikay8" target="_blank" rel="noopener noreferrer">
            <GitHub 
              fontSize="large"
            />
          </a>
          <a href="https://www.linkedin.com/in/mikayla-hill/" target="_blank" rel="noopener noreferrer">
            <LinkedIn 
            fontSize="large"
            />
          </a>
        </div>
      </Grid>
     
    </Grid>
  );
};

export default ContactMe;