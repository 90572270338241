// src/components/Projects.js
import React from 'react';
import { Grid } from '@material-ui/core';
import '../stylesheets/Projects.css';
import me from '../images/headshot.jpeg';
import AquaMapImg from '../images/AquaMap.png';
import WireframeImg from '../images/Wireframe.png';
import AshaImg from '../images/Asha.png';
import fitsLogo from '../images/fits-logo.png';
import ImageCarousel from './ImageCarousel';
import GitHubProfile from './GitHubProfile';

const Projects = () => {
    const images = [
        AquaMapImg,
        WireframeImg,
        AshaImg,
        fitsLogo,
        // Add more image URLs as needed
      ];
      const titles = [
        'Aqua Maps',
        'Medical Records Management System',
        'Asha for Education',
        'Project Fits',
        // Add more image URLs as needed
      ];
      const overview = [
        'AquaMaps aims to create an easy-to-use visual interface for viewing various water temperatures across a large area. To do this, we have created nodes on top of a map at the locations of different water temperature sensors in the Gainesville area. These nodes pictorially represent the current water temperature compared to the average water temperature of that node during the same time period.',
        'Worked in a team of 4 designing a web app for a Medical Records Management Company for over 3 months. Conducted focus groups to identify the user needs and identified key questions.',
        'Working with team 3 on a MERN website for an Asha for Educations documents page.  The purpose of the project is to update the current Asha to make it easier to find information for the users. the main features that will be focused on our creating a map to display the different chapters in India end their American counterparts.',
        'Fits is a react app to help users organize clothes and plan out outfits. ',
        // Add more image URLs as needed
      ];
      const techStack = [
        'HTML, CSS, JavaScript, TypeScript, Google Maps API, NOAA API, MongoDB and Node',
        'Figma',
        'MongoDB, Express, React, Node',
        'MongoDB, Express, React',
        // Add more image URLs as needed
      ];
      const url = [
        'https://github.com/Mikay8/11282group46',
        'https://www.figma.com/file/xkAwkj4kWG3S0hxZuQ3Xl1/mrg2-wireframes',
        'https://www.figma.com/file/xkAwkj4kWG3S0hxZuQ3Xl1/mrg2-wireframes',
        'https://github.com/Mikay8/project-fits',
        // Add more image URLs as needed
      ];
      //<GitHubProfile username="Mikay8" />
  return (
    
    <Grid 
    container spacing={2} 
    justifyContent="center"
    alignItems="center"
    >
      <Grid item xs={12} sm={12} md={12} >
        <h2>Projects</h2>
      </Grid>
      <Grid item xs={12} sm={12} md={12} >
       
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <ImageCarousel images={images} titles={titles} overview={overview} techStack={techStack} url={url}/>
      </Grid>

    </Grid>
  );
};

export default Projects;