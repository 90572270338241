// src/components/Experience.js
import React from 'react';
import { Grid } from '@material-ui/core';
import '../stylesheets/Experience.css';

const Experience = () => {
  return (
    
    <Grid container spacing={0}
        justifyContent='center'
    >
        <h2>Experience</h2>
    
      <Grid item xs={12} sm={12} md={12}>
        <div className='experienceBox'>
            <h3>Software Engineer - JP Morgan Chase - New York NY</h3>
            <h4>August 2023 - Present </h4>
        </div>

      </Grid>

      <Grid item xs={12} sm={12} md={12}>
      <div className='experienceBox'>
            <h3>Global Data Analyst Intern - Bloomberg - Princeton NJ</h3>
            <h4> June 2022 – August 2022 </h4>
            <div className='experienceBox-list'>
                    <p> - Developed a Python microservice to analyze over 200 different 
                      types of derivative exchange data and visualized the data using Qlik Sense</p>
                    <p> - Designed a proof-of-concept automation workflow tool for
                       analyzing bank loan submissions resulting in 10 hours a week saved in loans team productivity</p>
                    <p> - Created a Python script to automate stories for Bloomberg News
                       by looking at the National housing price index by state and region</p>
            </div>

        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <div className='experienceBox'>
            <h3>Teaching Assistant for Intro to Software Engineering - University of Florida - Gainesville FL</h3>
            <h4> June 2022 – August 2022 </h4>
              <div className='experienceBox-list'>
                    <p> - Lead a discussion section of 24 students in agile software practices, 
                      product planning, and product execution</p>
                    <p> - Oversaw 5 agile development teams of students in a 
                      semester-long project focused on web app creation</p>
                    <p> - Partnered with the professor to plan and implement 
                      lessons following the course curriculum for a class section of over 300 students</p>
              </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Experience;