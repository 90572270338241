// src/components/Title.js
import React from 'react';
import { Grid } from '@material-ui/core';
import '../stylesheets/Title.css';
import me from '../images/headshot2.png';
import MenuButton from './MenuButton';
import HeaderAnimation from './HeaderAnimation'

const texts = ['Hi Im Mikayla', 'Welcome to my Website', 'Hi Im Mikayla', 'Welcome to my Website'];
  
const Title = () => {
  return (
    <div className="title-container">
          <Grid 
            container spacing={0} 
            columnSpacing={2} 
          >
                <Grid item xs={12} sm={4} md={4}>
                <div className="title-profile-image-container">
                  <img src={me} alt="me" className="title-profile-image" />
                </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="title-card-header">
                    <HeaderAnimation texts={texts} />
                  </div>

                  <div className="title-card-content">
                    <p> I am currently a Software Engineer and 2023 grad of University of Florida. I am interested in UX/UI design and Full-Stack Web Applications<br/> </p>
                    <p2><MenuButton to="experience" text="Learn about my Experience" /></p2>
                  </div>

                </Grid>
                
          </Grid>
    </div>
          
  );
};

export default Title;