// src/components/ScrollingMenu.js
import React, { useState, useEffect } from 'react';
import '../stylesheets/ScrollingMenu.css';
import { Grid } from '@material-ui/core';
import MenuButton from './MenuButton';

const ScrollingMenu = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if(isOpen){
      //Reset button values so menu can close smoothly
      
    }else{
      
    }
  };

  const experienceCloseMenu = () => {
    document.getElementById('experience').scrollIntoView();
    setIsOpen(false);
  };
  const projectsCloseMenu = () => {
    document.getElementById('projects').scrollIntoView();
    setIsOpen(false);
  };
  const contactCloseMenu = () => {
    document.getElementById('contact').scrollIntoView();
    setIsOpen(false);
  };

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible( 
      //(prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) || currentScrollPos < 10
      currentScrollPos>300
    );
  
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, visible]);

  return (
    <div className={`slide-down-menu-overlay ${visible&&isOpen ? 'active' : 'hidden'}`}>
      <div className={`scrolling-menu ${visible ? 'active' : 'hidden'}`}>
        
        <ul className='scrolling-menu-header'>
          
          <li className="logoName"><MenuButton to="title" text="Mikayla Hill" /></li>
          <li className="menuItem"><MenuButton to="experience" text="Experience" /></li>
          <li className="menuItem"><MenuButton to="projects" text="Projects" /></li>
          <li className="menuItem"><MenuButton to="contact" text="Contact Me" /></li>
          
          <button className={`slide-down-menu-button ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </button>

        </ul>
      
        <div className={`slide-down-menu ${isOpen ? 'open' : ''}`}>
            
            <div className='slide-down-menu-content'>
                
              <Grid 
                container spacing={5} 
                
                
                justifyContent="center"
                alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={12} rowSpacing={9} >
                        <button className="slide-down-menu-content-button" id="experienceBtn" onClick={experienceCloseMenu}>Experience</button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                      <button className="slide-down-menu-content-button" id="projectsBtn" onClick={projectsCloseMenu}>Projects</button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                      <button className="slide-down-menu-content-button" id="contactBtn" onClick={contactCloseMenu}>Contact Me</button>
                  </Grid>
                </Grid>
                
            </div>
            
        </div>
        
</div> 
      </div>
  );
};

export default ScrollingMenu;
