// src/components/ImageCarousel.js
import React, { useState } from 'react';
import Slider from 'react-slick';
import Modal from 'react-modal';
import { Grid } from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../stylesheets/ImageCarousel.css';
import TextAnimation from './TextAnimation';

Modal.setAppElement('#root'); // Set the root element for accessibility

const ImageCarousel = ({ images,titles,overview,techStack,url }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const slider = React.useRef(null);
  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = 'unset';
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Slider ref={slider} {...settings}arrows={false}>
        { images.map((image, index) => (
            <div>
                
                <div key={index} >
                    
                    <Grid 
                    container spacing={0} 
                    alignItems='center'
                    >
                        <Grid item xs={0} sm={0} md={1} className='hide-small'>
                            
                            <button onClick={() => slider?.current?.slickPrev()}>&lt;</button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                            <img src={image} alt={`Image ${index}`} onClick={() => openModal(index)} className='image-carousel' />
                            
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                          <div className='imageCarouselContent'>
                            <h3>{titles[index]}</h3>
                            <p>{overview[index]}</p>
                            <p onClick={() => openModal(index)}><TextAnimation text={"Learn More"} animation="bounce" /></p>
                          </div>
                            
                            
                        </Grid>
                        <Grid item xs={0} sm={0} md={1} className='hide-small'>
                            
                            <button onClick={() => slider?.current?.slickNext()}>&gt;</button>
                        </Grid>

                       
                
                    </Grid>
                </div>
                
                
            </div>
                
        )) 
        }
      </Slider>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
        
      >
       
            <p onClick={closeModal}className="close-button"><TextAnimation text={"Close"} animation="bounce" /></p>
                 
            <img
            src={images[selectedImageIndex]}
            alt={`Image ${selectedImageIndex}`}
            className="modal-image"
            />
            <h3>{titles[selectedImageIndex]}</h3>  
            <p>{overview[selectedImageIndex]}</p>
            <h3>Tech Stack</h3>
            <p>{techStack[selectedImageIndex]}</p>

            <a href={url[selectedImageIndex]}>Look at the code</a>
            


              
      
    
      </Modal>
    </div>
  );
};

export default ImageCarousel;
